<template>
  <div class="selected-operators-sidebar">
    <v-layout row wrap class="pa-3">
      <OperatorSelectionSearch
        v-show="!operatorId"
        :effective-selected-operator-ids="effectiveSelectedOperatorIds"
        @input:select="
          setSelectedOperatorCompanyId({ companyId: $event.companyId })
        "
      />
      <SelectedOperatorDetail v-if="!!operatorId" />
    </v-layout>
    <div class="cr-sidebar-dialog--button-spacer"></div>
    <CRButton
      class="selected-operators-sidebar--action-btn"
      color="primary"
      :disabled="loading || !operatorId"
      @click="submit"
    >
      <span v-if="!loading">Add Operator</span>
      <CRProgressCircular v-else :size="27" />
    </CRButton>
  </div>
</template>

<script>
import { PhoneNumberUtil } from 'google-libphonenumber'
import { mapActions } from 'vuex'
import SelectedOperatorDetail from './SelectedOperatorDetail.vue'
import OperatorSelectionSearch from './OperatorSelectionSearch.vue'
import axios from 'axios'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import marketRates from '@/services/marketRates'
const phoneUtil = PhoneNumberUtil.getInstance()

export default {
  name: 'SelectedOperatorsForm',
  components: {
    OperatorSelectionSearch,
    SelectedOperatorDetail,
  },
  props: {
    effectiveSelectedOperatorIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
    }
  },
  computed: {
    customerAccountId() {
      return this.$store.getters['customerAccounts/getCustomerAccountId']
    },
    operatorId() {
      return this.$store.getters['selectedOperator/getCompanyId'] || null
    },
    operator() {
      return this.$store.getters['selectedOperator/getOperator']
    },
    rates() {
      return this.$store.getters['selectedOperator/getRates']
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      setSelectedOperatorCompanyId: 'selectedOperator/setCompanyId',
      clearSelectedOperator: 'selectedOperator/clearSelectedOperator',
    }),
    async submit() {
      // save new operator link
      this.loading = true

      let response = await axios.post(
        `/customerAccounts/${this.customerAccountId}/selectedOperators`,
        {
          operatorId: this.operatorId,
        }
      )
      await this.updateSelectedOperatorRates()
      this.showAlert({
        message: 'Operator added successfully',
        type: 'success',
      })

      this.loading = false
      EventBus.$emit('operator-selected')
      this.close()
    },
    async updateSelectedOperatorRates() {
      if (!this.rates.length) {
        return
      }
      const updateRates = deepClone(this.rates)
      for (const vtr of updateRates) {
        for (const rate of vtr.rates) {
          if (!rate.toggled) {
            rate.custom = null
          }
        }
      }
      const params = {
        customerAccountId: this.customerAccountId,
        companyId: this.operatorId,
        body: {
          vehicleTypeRates: updateRates,
        },
      }

      try {
        const res = await marketRates.updateSelectedOperatorRates(params)
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Unable to update selected operator rates',
        })
        console.error(e)
      }
    },
    close() {
      this.clearSelectedOperator()
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.selected-operators-sidebar {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 0px;

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px !important;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    border-radius: 0;
    transition: 0.1s all ease-in-out;
    z-index: 1000;

    &:disabled {
      background: $gray-light;
    }
  }

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  ::v-deep .v-select .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
    color: $black;
  }

  ::v-deep
    .v-input--is-disabled:not(.v-input--checkbox):not(.v-input--switch)
    .v-input__control
    .v-input__slot {
    background-color: $input-border-gray !important;

    input:disabled {
      background-color: $input-border-gray !important;
    }
  }
}
</style>
