<template>
  <v-layout class="affiliate-view" row wrap>
    <v-flex>
      <v-layout column class="referral-header">
        <v-layout row>
          <v-flex class="referral-header">
            <v-flex grow>
              <v-layout column>
                <div>Search</div>
                <v-text-field
                  v-model="searchText"
                  append-icon="$vuetify.icons.searchLarge"
                  flat
                  solo
                  @input="searchInput"
                />
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-flex>

    <v-flex v-if="!loading && !allAffiliatesPage.length" text-xs-center xs12>
      No Data Available
    </v-flex>

    <v-flex v-if="loading" xs12>
      <v-layout row wrap>
        <v-flex text-xs-center xs12>
          Retrieving Affiliates
          <v-progress-linear height="2" indeterminate />
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex
      v-for="(affiliate, affiliateIndex) in allAffiliatesPage"
      xs12
      :key="`${affiliate.id}-affiliate-row`"
    >
      <v-layout
        class="affiliate-row-container padding-a-4 border-radius-5 background-blue-pale"
        justify-space-between
      >
        <SelectedOperatorMiniDetail :operator="formatAffiliates(affiliate)" />
        <v-flex xs3 shrink>
          <v-layout column style="height: 100%">
            <v-layout
              row
              wrap
              class="affiliate-row right-border-radius"
              style="align-items: center; padding-top: 12px"
            >
              <v-flex
                style="
                  position: relative;
                  bottom: 24px;
                  display: flex;
                  align-items: center;
                "
                xs8
              >
                <v-layout row>
                  <v-layout column>
                    <v-btn
                      class="elevation-0 btn-primaryaction"
                      style="margin-top: 24px"
                      :disabled="
                        effectiveSelectedOperatorIds.includes(
                          affiliate.companyId
                        )
                      "
                      @click="$emit('input:select', affiliate)"
                    >
                      Select
                    </v-btn>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex v-if="affiliatePage && affiliatePage.length && !loading" xs12>
      <div class="float-right">
        <v-pagination
          v-model="currentPage"
          :length="parseInt(totalPages, 10)"
          :total-visible="5"
          class="elevation-0"
          @input="changePage"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import op from 'simple-object-path'
import { deepClone } from '@/utils/deepClone'
import { phoneFormatFilter } from '@/utils/phone'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { authComputed } from '@/state/helpers'
import { textLike } from '@/utils/predefined'
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import affiliates from '@/services/affiliates'
import SelectedOperatorMiniDetail from './SelectedOperatorMiniDetail.vue'

export default {
  components: {
    SelectedOperatorMiniDetail,
  },
  data() {
    return {
      filters: filter(),
      sortUp: true,
      affiliates: [],
      affiliatePage: [],
      currentPage: 1,
      perPage: 3,
      loading: false,
      offeredLoading: false,
      searchText: undefined,
      phoneFormatFilter,
      op,
      debounce: null,
      immediate: true,
      filterByCategory: 'name',
      totalPages: 100,
    }
  },
  props: {
    effectiveSelectedOperatorIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...authComputed,
    allAffiliatesPage() {
      return this.affiliatePage
    },
  },
  mounted() {
    this.getAllAffiliates()
    EventBus.$on('refresh-detail', () => {
      this.$emit('refresh-query-request')
    })
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    searchInput(arg) {
      this.immediate = false
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        this.getAllAffiliates()
      }, 500)
    },
    async getAllAffiliates(arg) {
      this.loading = true
      const page = arg && arg.page ? arg.page : 1
      this.filters.clear()

      let sorts = sort()
      if (this.sortUp) {
        sorts.add({
          prop: 'referralCount',
          direction: 'desc',
        })
      } else {
        sorts.add({
          prop: 'referralCount',
          direction: 'asc',
        })
      }

      let selectedPredefined = deepClone(textLike[0])
      selectedPredefined.controls[0].value = this.searchText
      const textFilter = {
        column: {
          _t_id: 'text_search_refer',
          prop: 'name',
          filterType: 'contains',
          method: 'and',
        },
        selectedPredefined,
        value: this.searchText,
      }
      this.filters.remove(textFilter)

      if (this.searchText) {
        this.filters.add(this.filters.createParent('and'), textFilter)
      }

      this.affiliatePage = await affiliates
        .getAffiliates({
          sorts: sorts.asQueryParams(),
          filters: this.filters.asQueryParams(),
          pageSize: this.perPage,
          page,
        })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.count / this.perPage)
          const res = data.resultList
          return res
        })
        .then((res) => {
          return res
        })
      this.loading = false
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllAffiliates({ page: currentPage })
    },
    formatAffiliates(affiliate) {
      return {
        ...affiliate,
        addressName: affiliate?.address?.completeAddress,
        garageNames: affiliate?.garages?.map(
          (g) => g?.address?.completeAddress
        ),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.referral-header {
  display: flex;
  flex-grow: 10;
  justify-content: space-between;

  .flex {
    flex-grow: 2;
    padding-right: 10px;
  }

  .referral-filter {
    position: relative;
    display: flex;
    min-height: inherit;
    margin-bottom: 8px;
    margin-left: 20px;
    color: rgba($black-base, 0.54);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    div {
      display: flex;
      align-items: center;
      height: 2.5em;
      font-size: 16px;
      line-height: 1;
    }

    i {
      margin-left: 5px;
      font-size: 16px;
    }
  }
}

.location-container {
  display: flex;
  align-items: center;
  height: 120px;
  padding-bottom: 5px;
}

.input-false ::v-deep input {
  color: $black !important;
}

.input-false ::v-deep .v-input__slot {
  background-color: $gray-light !important;
}

.affiliate-row {
  padding: 12px 6px 0 6px;
  margin-bottom: 4px;
  background-color: $blue-pale;

  .v-text-field {
    .v-input__control {
      min-height: 34px;
      max-height: 50px;

      .v-input__slot {
        margin-bottom: 0;
      }
    }

    input {
      padding: 0;
    }
  }
}

.affiliate-view > .flex.xs12 {
  margin-bottom: 20px;
}

.affiliate-name {
  color: $blue;
  word-wrap: break-word;
}

.light {
  color: $blue-dull;
}

.column-header {
  padding-left: 6px;
  min-height: 30px;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
}

.right-border-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.left-border-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>
